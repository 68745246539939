<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="800px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
    center
  >
    <div class="orderTest-container">
      <my-table
        v-if="type == 1"
        ref="table1"
        :table-data="list"
        :show-index="false"
        :show-select="false"
        :columns="columns1"
        :check-list="checkList1"
        :loading.sync="loading"
      ></my-table>
      <my-table
        v-else
        ref="table2"
        :table-data="list"
        :show-index="false"
        :show-select="false"
        :columns="columns2"
        :check-list="checkList2"
        :loading.sync="loading"
      ></my-table>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import MyTable from '@/baseComponents/table'
  import { locationAbnormal, getLineAbnormal } from '@/api/performance'
  export default {
    name: 'Abnormal',
    components: {
      MyTable,
    },
    data() {
      return {
        type: 1,
        ids: '',
        showDialog: false,
        title: '异常',
        loading: false,
        checkList1: ['客户名称', '门店地址', '定位地址', '拜访动作', '时间'],
        columns1: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '门店地址',
            prop: 'info_address',
            width: '',
          },
          {
            order: 3,
            label: '定位地址',
            prop: 'address',
            width: '',
          },
          {
            order: 4,
            label: '拜访动作',
            prop: 'type',
            width: '',
          },
          {
            order: 5,
            label: '时间',
            prop: 'create_at',
            width: '',
          },
        ],
        checkList2: [
          '线路名称',
          '客户名称',
          '门店地址',
          '老板姓名',
          '联系电话',
        ],
        columns2: [
          {
            order: 1,
            label: '线路名称',
            prop: '',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '门店地址',
            prop: 'address',
            width: '',
          },
          {
            order: 4,
            label: '老板姓名',
            prop: 'boss',
            width: '',
          },
          {
            order: 5,
            label: '联系电话',
            prop: 'mobile',
            width: '',
          },
        ],
        list: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          if (this.type == 1) {
            this.getLocationAbnormal()
          } else if (this.type == 2) this.getLineAbnormal()
        } else {
          this.ids = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async getLocationAbnormal() {
        this.loading = true
        let { data } = await locationAbnormal({ ids: this.ids })
        console.log(data)
        this.list = data
        this.loading = false
      },
      async getLineAbnormal() {
        this.loading = true
        let { data } = await getLineAbnormal({ ids: this.ids })
        console.log(data)
        this.list = data
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
