var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "800px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _vm.type == 1
            ? _c("my-table", {
                ref: "table1",
                attrs: {
                  "table-data": _vm.list,
                  "show-index": false,
                  "show-select": false,
                  columns: _vm.columns1,
                  "check-list": _vm.checkList1,
                  loading: _vm.loading,
                },
                on: {
                  "update:loading": function ($event) {
                    _vm.loading = $event
                  },
                },
              })
            : _c("my-table", {
                ref: "table2",
                attrs: {
                  "table-data": _vm.list,
                  "show-index": false,
                  "show-select": false,
                  columns: _vm.columns2,
                  "check-list": _vm.checkList2,
                  loading: _vm.loading,
                },
                on: {
                  "update:loading": function ($event) {
                    _vm.loading = $event
                  },
                },
              }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }