<template>
  <!-- NAME[epic=绩效] 拜访统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item prop="depart_id">
        <el-select
          v-model="form.depart_id"
          placeholder="部门"
          style="width: 120px"
        >
          <el-option
            v-for="(item, index) in departmentList"
            :key="index"
            :label="item.text"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          style="width: 140px"
          placeholder="业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <!-- <el-form-item label="" prop="">
        <el-button>导出</el-button>
      </el-form-item> -->
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '位置异常'">
            <span
              style="color: red; text-decoration: underline; cursor: pointer"
              @click="handleLocation(row)"
            >
              {{ row.visit_ab_num }}
            </span>
          </div>
          <div v-else-if="item.label == '线路漏店'">
            <span
              style="color: red; text-decoration: underline; cursor: pointer"
              @click="handleLine(row)"
            >
              {{ row.un_visit_line_num }}
            </span>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" align="center" label="操作">
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <abnormal ref="abnormal"></abnormal>
    <visit-detail ref="visitDetail"></visit-detail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { peopleList, departmentList, visitList } from '@/api/performance'
  import Abnormal from './components/abnormal'
  import VisitDetail from './components/visitDetail'

  export default {
    name: 'Summary',
    components: { Abnormal, VisitDetail },
    data() {
      return {
        departmentList: [],
        staffList: [],
        form: {
          depart_id: '', //部门ID
          user_id: '', //员工ID
          start_time: '', //开始时间 如2020-12-22
          end_time: '', //结束时间 如2020-12-22
          pageSize: 10,
          pageNo: 1,
        },
        time: [],
        checkList: [
          '业务员',
          '拜访任务',
          '拜访总家数',
          '任务达成率',
          '线路总家数',
          '拜访线路家数',
          '线路拜访完成率',
          '位置异常',
          '线路漏店',
        ],
        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            order: 1,
            label: '业务员',
            prop: 'user_name',
            width: '90',
          },
          {
            order: 2,
            label: '拜访任务',
            prop: 'target_count',
            width: '',
          },
          {
            order: 3,
            label: '拜访总家数',
            prop: 'result_count',
            width: '',
          },
          {
            order: 4,
            label: '任务达成率',
            prop: 'rate',
            width: '',
          },
          {
            order: 5,
            label: '线路总家数',
            prop: 'line_cust_num',
            width: '',
          },
          {
            order: 6,
            label: '拜访线路家数',
            prop: 'visit_line_num',
            width: '',
          },
          {
            order: 7,
            label: '线路拜访完成率',
            prop: 'rate2',
            width: '',
          },
          {
            order: 8,
            label: '位置异常',
            prop: 'visit_ab_num',
            width: '',
          },
          {
            order: 9,
            label: '线路漏店',
            prop: 'un_visit_line_num',
            width: '',
          },
        ],
        loading: false,
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      // 部门下拉
      departmentList().then((res) => {
        this.departmentList = res.data.list
      })
      peopleList({ pageSize: -1, is_close: 0 }).then((res) => {
        console.log(res)
        this.staffList = res.data
      })
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await visitList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleCheck(index, row) {
        console.log(row)
        if (this.time) {
          this.$refs.visitDetail.timeSelect =
            this.time.length == 0 ? null : this.time
        } else {
          this.$refs.visitDetail.timeSelect = null
        }
        this.$refs.visitDetail.form.user_id = Number(row.user_id)
        this.$refs.visitDetail.showDialog = true
      },
      // 位置异常点击
      handleLocation(row) {
        console.log(row)
        this.$refs.abnormal.type = 1
        this.$refs.abnormal.title = '位置异常明细'
        this.$refs.abnormal.ids = JSON.stringify(row.visit_ab_sub)
        this.$refs.abnormal.showDialog = true
      },
      // 线路漏店点击
      handleLine(row) {
        console.log(row)
        this.$refs.abnormal.type = 2
        this.$refs.abnormal.title = '线路漏店明细'
        this.$refs.abnormal.ids = JSON.stringify(row.un_visit_line_sub)
        this.$refs.abnormal.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
