<template>
  <el-dialog
    :modal="false"
    title="拜访明细"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="" prop="user_id">
          <el-select
            v-model="form.user_id"
            clearable
            style="width: 140px"
            placeholder="业务员"
          >
            <el-option
              v-for="(i, idx) in staffList"
              :key="idx"
              :value="i.user_id"
              :label="i.user_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-date-picker
            v-model="time"
            style="width: 240px"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button
            type="primary"
            @click="
              () => {
                form.pageSize = 10
                form.pageNo = 1
                fetchData()
              }
            "
          >
            查询
          </el-button>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-button @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" stripe :data="list">
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '位置'">
              <span v-if="row.abnormal == 0">正常</span>
              <span
                v-else-if="row.abnormal == 1"
                style="color: red"
                @click="handleAbnormal(row)"
              >
                异常
              </span>
              <span v-else></span>
            </div>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <show-err ref="showErr"></show-err>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { day_30 } from '@/utils/Time'
  import { peopleList, visitDetail } from '@/api/performance'
  import ShowErr from './showErr'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: '',
    components: { ShowErr },
    data() {
      return {
        showDialog: false,
        time: [],
        form: {
          pageNo: 1,
          pageSize: 10,
          user_id: '', //414277065978040
          start_time: '1', //x)V^gmn
          end_time: '1', //eKrQS
        },
        info: {},
        // 带过来的时间
        timeSelect: [],
        list: [],
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        checkList: ['客户名称', '拜访动作', '位置', '操作时间'],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '拜访动作',
            prop: 'action_name',
            width: '',
          },
          {
            order: 3,
            label: '位置',
            prop: 'abnormal',
            width: '',
          },
          {
            order: 4,
            label: '操作时间',
            prop: 'create_at',
            width: '',
          },
        ],
        staffList: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.initTime()
          this.fetchData()
        } else {
          this.form.user_id = ''
          this.timeSelect = null
          this.time = []
        }
      },
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      peopleList({ pageSize: -1 }).then((res) => {
        console.log(res)
        this.staffList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await visitDetail(this.form)
        this.list = data.rows
        this.total = Number(data.total)
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      initTime() {
        console.log('th', this.timeSelect)
        if (this.timeSelect) {
          this.time = this.timeSelect
          this.form.start_time = this.timeSelect[0]
          this.form.end_time = this.timeSelect[1]
        } else {
          this.time = day_30()
          this.form.start_time = this.time[0]
          this.form.end_time = this.time[1]
        }
      },
      handleAbnormal(row) {
        console.log(row)
        this.$refs.showErr.form = {
          photo_addr: row.visit_address,
          cust_addr: row.cust_address,
        }
        this.$refs.showErr.showDialog = true
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/visit/summary-detail-export',
          '拜访明细.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
