var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "",
        visible: _vm.showDialog,
        width: "450px",
        top: "20vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "门店位置:", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.form.cust_addr) + " "),
              ]),
              _c("div"),
              _c("el-form-item", { attrs: { label: "操作位置:", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.form.photo_addr) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }