<template>
  <el-table v-loading="loading" stripe :data="tableData">
    <!-- 序号 -->
    <el-table-column v-if="showIndex" align="center" type="index" width="50">
      <template slot="header">
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference" type="text">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover>
      </template>
    </el-table-column>
    <!-- 选择框 -->
    <el-table-column
      v-if="showSelect"
      align="center"
      type="selection"
      width="50"
    ></el-table-column>
    <el-table-column
      v-for="(item, tableIndex) in finallyColumns"
      :key="tableIndex"
      :prop="item.prop"
      :label="item.label"
      width=""
      align="center"
    ></el-table-column>
  </el-table>
</template>
<script>
  import _ from 'lodash'
  export default {
    name: 'Table',
    components: {},
    props: {
      showIndex: {
        type: Boolean,
        default: () => true,
      },
      loading: {
        type: Boolean,
        default: () => false,
      },
      showSelect: {
        type: Boolean,
        default: () => true,
      },
      tableData: {
        type: Array,
        default: () => [],
      },
      checkList: {
        type: Array,
        default: () => [],
      },
      columns: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {}
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
